<script setup lang="ts">
import NotificationPreview from './NotificationPreview.vue';
import { groupBy, map } from 'lodash-es';
import { differenceInDays, format, startOfDay } from 'date-fns';
import { type Notification } from '../types';
import { useReadAllNotifications } from '../api/readAllNotifications';
import { useGetNotificationsInfinite } from '../api/getNotifications';
import { useRoutes } from '@glow/shared/lib/routes';

const emit = defineEmits<{
  (e: 'select-notification', notification: Notification): void;
}>();

const { getUrl } = useRoutes();
const { data: notificationInfiniteData, isLoading } =
  useGetNotificationsInfinite();
const unreadNotificationsFlat = useFlatInfiniteData(notificationInfiniteData);
const { mutate: readAll } = useReadAllNotifications();

function getNotificationGroupTitle(date: Date) {
  const names = ['Сегодня', 'Вчера', 'Позавчера'];
  const difference = differenceInDays(new Date(), date);
  return difference <= names.length
    ? names[difference]
    : format(date, 'd MMMM');
}
type NotificationGroup = {
  title: string;
  notifications: Notification[];
};
const notificationGroups = computed<NotificationGroup[]>(() =>
  map(
    groupBy(unreadNotificationsFlat.value, (notification) =>
      getNotificationGroupTitle(startOfDay(notification.createdAt))
    ),
    (notifications, title) => ({ title, notifications })
  )
);
function selectNotifiaction(notification: Notification) {
  emit('select-notification', notification);
}
</script>

<template>
  <div class="notifications">
    <AppButton
      v-if="notificationGroups.length > 1"
      variant="link"
      color="secondary"
      class="text-end"
      @click="readAll"
    >
      Отметить все, как прочитанное
    </AppButton>
    <div class="notifications__days">
      <div v-for="group in notificationGroups" :key="group.title">
        <div class="notifications__day-text">{{ group.title }}</div>
        <div class="notifications__list">
          <NotificationPreview
            v-for="notification in group.notifications"
            :key="notification.id"
            :notification="notification"
            class="notifications__item"
            @click="selectNotifiaction(notification)"
          />
        </div>
      </div>
    </div>

    <AppLoader v-if="isLoading" />
    <div v-else-if="notificationGroups.length < 1" class="text-center">
      Уведомлений пока нет
    </div>
    <AppButton fluid variant="outlined" :to="getUrl('notifications')">
      Посмотреть все
    </AppButton>
  </div>
</template>

<style lang="scss" scoped>
.notifications {
  &__days {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  &__day-text {
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    text-transform: uppercase;
    color: var(--app-color-black-secondary);
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__item {
    cursor: pointer;
  }
}
</style>
