<script setup lang="ts">
import { type Notification } from '../types';
import { getNotificationText, getNotificationTitle } from '../notifications';
import { formatDate } from '@glow/shared/utils/format';

const props = defineProps<{
  notification: Notification;
}>();
</script>

<template>
  <div
    :class="['notification', { notification_read: props.notification.isRead }]"
  >
    <div class="d-flex flex-column gap-2">
      <div class="d-flex align-items-center justify-content-between">
        <span class="font-bold">
          {{ getNotificationTitle(props.notification.type) }}
        </span>
        <span class="notification__date">
          {{
            formatDate(props.notification.createdAt, { representation: 'time' })
          }}
        </span>
      </div>
      <div class="notification__text">
        {{ getNotificationText(props.notification) }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@glow/shared/assets/core.scss';

.notification {
  display: flex;
  align-items: center;
  gap: var(--app-spacing-4);
  padding: var(--app-spacing-4);
  background: var(--app-color-white-secondary);
  border: 2px solid var(--app-color-white-quaternary);
  border-radius: var(--app-br-md);

  @include media-breakpoint-down(sm) {
    padding: 16px;
  }

  &_read {
    background: #f7f7f8;
  }

  &__icon {
    width: 48px;
    height: 48px;
    color: var(--app-color-primary);

    @include media-breakpoint-down(sm) {
      width: 32px;
      height: 32px;
    }
  }

  &__text {
    font-size: 15px;
    line-height: 17px;
  }

  &__date {
    color: var(--app-black-secondary);
    font-size: 13px;
  }
}
</style>
